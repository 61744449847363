* {
    box-sizing: border-box;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
#root {
    height: 100vh;
}
::selection {
    background-color: $clr3;
}

html {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.6;
    // color: $clr2;
    // height: 100vh;
}

body {
    font-size: rem(18px);
    // margin: 0 auto;
    @extend .theme;
    min-height: 100vh;
    font-family: "Airbnb Cereal App Book";
}

li {
    list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Airbnb Cereal App Book", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    margin: 10px 0 0.6em;
    line-height: 1;
    text-align: center;
    text-transform: capitalize;
}

// main,
// footer {
//     padding: 5px;
// }

img {
    width: 100%;
}

p {
    margin: 0 0 1em;
}

a {
    text-decoration: none;
    color: inherit;
}
a:hover {
    text-decoration: underline;
}
ul {
    list-style: none;
}
button {
    // background-image: none;
    background-color: rgba(255, 250, 240, 0);
    // box-shadow: 0px 5px 5px 1px purple;
    border: 5px solid purple;
    cursor: pointer;
    &:hover {
        // background-color: rebeccapurple;
        color: rgb(37, 2, 36);
        // font-size: 20px;
        border: 5px solid rgb(224, 154, 224);
    }
}
