/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: "Airbnb Cereal App Book";
    font-style: normal;
    font-weight: normal;
    src: local("Airbnb Cereal App Book"), url("../../fonts/Cereal/AirbnbCerealBook.woff") format("woff");
}
// @font-face {
//     font-family: "Gochi-Hand";
//     font-style: normal;
//     font-weight: normal;
//     src: url("../../fonts/Gochi_Hand/GochiHand-Regular.ttf") format("ttf"),
//         url("https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap");
// }
// @font-face {
//     font-family: "PermanentMarker";
//     // font-style: normal;
//     // font-weight: normal;
//     src: url("../../fonts/Permanent_Marker/PermanentMarker-Regular.ttf") format("ttf");
// }
@font-face {
    font-family: "Airbnb Cereal App Light";
    font-style: normal;
    font-weight: normal;
    src: local("Airbnb Cereal App Light"), url("../../fonts/Cereal/AirbnbCerealLight.woff") format("woff");
}

@font-face {
    font-family: "Airbnb Cereal App Medium";
    font-style: normal;
    font-weight: normal;
    src: local("Airbnb Cereal App Medium"), url("../../fonts/Cereal/AirbnbCerealMedium.woff") format("woff");
}

@font-face {
    font-family: "Airbnb Cereal App Bold";
    font-style: normal;
    font-weight: normal;
    src: local("Airbnb Cereal App Bold"), url("../../fonts/Cereal/AirbnbCerealBold.woff") format("woff");
}

@font-face {
    font-family: "Airbnb Cereal App Extra Bold";
    font-style: normal;
    font-weight: normal;
    src: local("Airbnb Cereal App Extra Bold"), url("../../fonts/Cereal/AirbnbCerealBold.woff") format("woff");
}

@font-face {
    font-family: "Airbnb Cereal App Black";
    font-style: normal;
    font-weight: normal;
    src: local("Airbnb Cereal App Black"), url("../../fonts/Cereal/AirbnbCerealBlack.woff") format("woff");
}
