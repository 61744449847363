header {
    @extend .footer-header;
    position: sticky;
    width: 100vw;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
}
.nav {
    height: 40px;
    li {
        // margin: 15px 0px 5px 0;
        color: $clr1;
        margin-inline-end: 20px;
        font-size: 20px;
    }
}
.logo {
    margin: 5px;
    padding-right: 20px;
    font-size: 30px;
    text-align: center;
    color: $clr7;
    text-shadow: 1px 1px saddlebrown;
}
