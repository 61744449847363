// button {
//     border: 0;
//     background-color: none;
//     color: rgba(0, 0, 0, 0.87);
//     padding: 5px 10px;
//     margin-right: 15px;
//     margin-left: 15px;
//     border-radius: 5px;
//     text-transform: capitalize;
//     cursor: pointer;
//     &:hover {
//         background-color: rgb(0, 0, 0);
//         color: rgb(248, 241, 241);
//     }
// }

form > * {
    display: block;
    width: 200px;

    font-size: 16px;
}

input:not([type="radio"]),
select,
textarea {
    background-color: unset;
    border: none;
    max-height: 400px;
    min-height: 40px;
    min-width: 150px;
    margin: 15px 10px 10px 0px;
    background-color: #fff;
    cursor: pointer;
    font-size: 18px;
    border: 1px solid $clr2;
    resize: none;
}
textarea {
    min-height: 200px;
    min-width: 500px;
}
.MuiButton-root {
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 0.2rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Airbnb Cereal App Book";
    font-weight: 500;
    line-height: 1.75;
    border-radius: 14px;
    letter-spacing: 0.02857em;
    text-transform: capitalize;
}
* :focus {
    border-color: none;
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
