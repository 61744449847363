.container {
    max-width: 1500px;
    // margin:40px auto;
    // text-align: center;
    // align-items: center;
    // justify-content: center;
    display: flex;
    // flex-direction: umn;
    margin: 0 auto;
    // height: 100vh;
    // margin-top: 100px;

    .map {
        width: 500px;
        height: 700px;
        padding: 10px;
        text-align: center;
        // margin-bottom: 50px;
        border: 0;
        box-shadow: 0 0 10px 1px gainsboro;
    }
}
@media only screen and (max-width: 600px) {
    .container {
        .map {
            width: 350px;
            height: 700px;
            // margin-bottom: 50px;
            border: 0;
            box-shadow: 0 0 10px 1px gainsboro;
        }
    }
}
@media only screen and (max-width: 900px) {
    .container {
        flex-direction: column;
        align-items: center;
    }
}

.list-container {
    width: 100%;
    // height: ;
    overflow-y: scroll;
    // padding: 5px;
    // scrollbar-arrow-color: purple;
    &::-webkit-scrollbar {
        width: 20px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    & ::-webkit-scrollbar-thumb {
        background: red;
        border-radius: 10px;
    }

    /* Handle on hover */
    & ::-webkit-scrollbar-thumb:hover {
        background: #b30000;
    }
    margin-left: 14px;

    // background-color: gold;
    .list-grid {
        margin-bottom: 60px;
        justify-content: center;
        // text-align: center;
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(auto-fill, 220px);
        // grid-template-rows: 300px;
    }
}
