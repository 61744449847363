// Colors
$clr1: #ffff;
$clr2: #d6d4e0;
$clr3: #b8a9c9;
$clr4: #9fedd7;
$clr5: #026670;
$clr6: rgb(10, 10, 10);
$clr7: #622569;

// Breakpoints
$mobile-breakpoint: 460px;
$narrow-breakpoint: 620px;
$normal-breakpoint: 960px;

.theme {
    background-color: #ffffff;
    color: #aaacae;
}
.theme-black {
    background-color: $clr6;
    color: $clr7;
}
.footer-header {
    background-color: #ffffff;
    color: $clr6;
    box-shadow: 0 0 10px 1px gainsboro;
}
