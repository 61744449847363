.box {
    // position: relative;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    // background-color: #fff;
    box-shadow: 0px 0px 4px -1px $clr6;
    // // overflow: hidden;
    // border-radius: 8px;
    // background: white;
    // color: #ababab;
    // -webkit-transition: opacity 0.3s ease-in-out, -webkit-box-shadow 0.1s ease-out;
    // transition: opacity 0.3s ease-in-out, -webkit-box-shadow 0.1s ease-out;
    // transition: box-shadow 0.1s ease-out, opacity 0.3s ease-in-out;
    // transition: box-shadow 0.1s ease-out, opacity 0.3s ease-in-out, -webkit-box-shadow 0.1s ease-out;
    // // position: relative;
    @extend .theme;

    div {
        height: 200px;
        width: 100%;
        // background-color: aqua;
        img {
            object-fit: contain;
            height: 100%;
            // width: 90%;
            margin: 0 auto;
        }
    }

    a {
        text-decoration: none;
    }
}
.box:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
