/* Breakpoint mixins */

@mixin for-mobile-layout {
    @media (max-width: 300px) {
        @content;
    }
}

@mixin for-narrow-layout {
    @media (min-width: 500px) {
        @content;
    }
}

@mixin for-normal-layout {
    @media (min-width: 600px) {
        @content;
    }
}

@mixin for-wide-layout {
    @media (min-width: 800px) {
        @content;
    }
}

/* Media  Mixins */
@mixin aspect-ratio($width, $height) {
    position: relative;
    overflow: hidden;
    &::before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    > * {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    > img {
        height: 100%;
        object-fit: cover;
    }
}

// Mixin for removing hover efects on iphone screen
@mixin hover-supported {
    @media not all and (pointer: coarse) {
        &:hover {
            @content;
        }
    }
}
@mixin clean-list {
    list-style: none;
}
