footer {
    // position: absolute;
    bottom: 0;
    width: 100%;
    @extend .footer-header;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    div {
        margin: 10px;
        color: $clr6;
    }
}
